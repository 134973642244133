import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, map } from 'rxjs';
import { DataFirebaseService } from './data-firebase.service';

type Theme = 'Dark' | 'Red' | 'Blue' | 'Green';
type ThemeClass = 'theme-dark' | 'theme-red' | 'theme-blue' | 'theme-green';

const getThemeClass = (theme: Theme): ThemeClass => {
  switch (theme) {
    case 'Dark':
      return 'theme-dark';
    case 'Red':
      return 'theme-red';
    case 'Blue':
      return 'theme-blue';
    case 'Green':
      return 'theme-green';
    default:
      console.warn('Unknown theme: ' + theme);
      return 'theme-dark';
  }
};

export const themeResolver: ResolveFn<Observable<ThemeClass>> = () => {
  const dataService = inject(DataFirebaseService);
  const themeClass$ = dataService.settings$.pipe(
    map((settings) => getThemeClass(settings.colorTheme as Theme)),
  );
  return themeClass$;
};
