import { inject, NgModule } from '@angular/core';
import {
  NavigationEnd,
  PreloadAllModules,
  Router,
  RouterModule,
  Routes,
} from '@angular/router';
import {
  redirectUnauthorizedTo,
  canActivate,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';
import { TabsPage } from './tabs/tabs.page';
import { themeResolver } from './theme.resolver';
import { migrationGuard } from './migrations/migration.guard';
import { AnalyticsService } from './analytics.service';

const redirectToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['/tabs/machines']);

const redirectToLoginGuard = canActivate(redirectToLogin);
const redirectLoggedInToHomeGuard = canActivate(redirectLoggedInToHome);

const routes: Routes = [
  // Authenticated routes
  {
    path: '',
    redirectTo: '/tabs/machines',
    pathMatch: 'full',
  },
  {
    path: 'tabs',
    component: TabsPage,
    resolve: {
      theme: themeResolver,
    },
    runGuardsAndResolvers: 'always',
    canActivate: [...redirectToLoginGuard.canActivate, migrationGuard],
    data: {
      ...redirectToLoginGuard.data,
    },
    children: [
      {
        path: 'machines',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomePageModule),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./reports/reports.module').then((m) => m.ReportsPageModule),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./account/account.module').then((m) => m.AccountPageModule),
      },
    ],
  },

  // Unauthenticated routes
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
    ...redirectLoggedInToHomeGuard,
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule,
      ),
    ...redirectLoggedInToHomeGuard,
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterPageModule),
    ...redirectLoggedInToHomeGuard,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  private analyticsService = inject(AnalyticsService);
  private router = inject(Router);
  constructor() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.analyticsService.trackPageView();
      }
    });
  }
}
