<ion-tabs [ngClass]="(route.data | async)?.['theme']">
  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="machines">
      <ion-icon name="home"></ion-icon>
      Machines
    </ion-tab-button>
    <ion-tab-button tab="reports">
      <ion-icon name="bar-chart"></ion-icon>
      Reports
    </ion-tab-button>
    <ion-tab-button tab="account">
      <ion-icon name="person-circle"></ion-icon>
      Account
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
