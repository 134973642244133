/* eslint-disable deprecation/deprecation */
import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { LoadingController } from '@ionic/angular/standalone';
import { firstValueFrom } from 'rxjs';
import { DataFirebaseService } from 'src/app/data-firebase.service';
import { MachineModel } from '../models/machine.model';
import { captureException } from '@sentry/angular';
import { environment } from '../../environments/environment';
import { deleteObject, ref, Storage } from '@angular/fire/storage';

const contentTypeToExtension = (contentType: string) => {
  switch (contentType) {
    case 'image/jpeg':
      return 'jpg';
    case 'image/png':
      return 'png';
    case 'image/gif':
      return 'gif';
    case 'image/webp':
      return 'webp';
    case 'image/avif':
      return 'avif';
    default:
      console.error('Unknown content type:', contentType);
      return 'jpg';
  }
};

const getCorrectUrl = (originalUrl: string) => {
  // covers basse64 images
  if (!originalUrl.startsWith('http')) {
    return originalUrl;
  }

  const url = new URL(originalUrl);

  if (url.hostname !== 'storage.googleapis.com') {
    return originalUrl;
  }

  const path = url.pathname.replace(
    new RegExp(`\/${environment.firebase.storageBucket}\/`),
    '',
  );

  return `http://${environment.firebase.storageBucket}.storage.googleapis.com/${path}`;
};

type Progress =
  | {
      type: 'progress';
      current: number;
      total: number;
    }
  | {
      type: 'done';
    };

async function* migrateImages(
  dataService: DataFirebaseService,
  storage: Storage,
): AsyncGenerator<Progress, void, unknown> {
  const machines = await firstValueFrom(dataService.machines$);

  const notMigratedMachines = machines.filter(
    (machine: MachineModel) => machine.image,
  );

  if (notMigratedMachines.length === 0) {
    yield { type: 'done' };
    return;
  }

  for (const [index, machine] of notMigratedMachines.entries()) {
    yield {
      type: 'progress',
      current: index,
      total: notMigratedMachines.length,
    };

    if (!machine.image) {
      continue;
    }

    try {
      const url = getCorrectUrl(machine.image);
      const isBase64 = !machine.image.startsWith('http');
      const response = await fetch(url);
      const blob = await response.blob();
      const extension = contentTypeToExtension(blob.type);
      const fileName = 'image' + '.' + extension;
      const file = new File([blob], fileName, {
        type: blob.type,
      });
      console.log('file :>> ', file.name);
      await dataService.addImageToMachine(machine._id, file);
      if (!isBase64) {
        try {
          await deleteObject(ref(storage, machine.image));
        } catch {
          console.error('Error deleting image', machine.image);
        }
      }
      await dataService.updateMachine({
        _id: machine._id,
        image: null,
      });
    } catch (error) {
      captureException(error, {
        extra: {
          machineId: machine._id,
        },
      });
    }
  }

  yield { type: 'done' };
}

async function* migrateServiceUpdates(dataService: DataFirebaseService) {
  const serviceUpdates = await firstValueFrom(dataService.services$);
  const notMigratedServiceUpdates = serviceUpdates.filter(
    (su) => su.machine && !su.machineId,
  );
  const machines = await firstValueFrom(dataService.machines$);

  if (notMigratedServiceUpdates.length === 0) {
    yield { type: 'done' };
    return;
  }

  for (const [index, serviceUpdate] of notMigratedServiceUpdates.entries()) {
    if (serviceUpdate.machine && !serviceUpdate.machineId) {
      yield {
        type: 'progress',
        current: index,
        total: notMigratedServiceUpdates.length,
      };

      try {
        const machine = machines.find((m) => m.name === serviceUpdate.machine);
        if (!machine) {
          continue;
        }
        await dataService.addServiceUpdate(machine._id, serviceUpdate);
      } catch (error) {
        captureException(error, {
          extra: {
            serviceUpdateId: serviceUpdate.id,
          },
        });
      }
    }
  }

  yield { type: 'done' };
}

export const migrationGuard: CanActivateFn = async () => {
  const loadingCtrl = inject(LoadingController);
  const dataService = inject(DataFirebaseService);
  const storage = inject(Storage);

  const imagesLoader = await loadingCtrl.create({
    message: 'Migrating images...',
  });

  for await (const progress of migrateImages(dataService, storage)) {
    if (progress.type === 'done') {
      imagesLoader.isOpen = false;
    } else if (progress.type === 'progress' && !imagesLoader.isOpen) {
      imagesLoader.isOpen = true;
    } else if (progress.type === 'progress') {
      imagesLoader.message = `Migrating images... (${progress.current}/${progress.total})`;
    }
  }

  const serviceUpdatesLoader = await loadingCtrl.create({
    message: 'Migrating service updates...',
  });

  for await (const progress of migrateServiceUpdates(dataService)) {
    if (progress.type === 'done') {
      serviceUpdatesLoader.isOpen = false;
    } else if (progress.type === 'progress' && !serviceUpdatesLoader.isOpen) {
      serviceUpdatesLoader.isOpen = true;
    } else if (progress.type === 'progress') {
      serviceUpdatesLoader.message = `Migrating service updates... (${progress.current}/${progress.total})`;
    }
  }

  imagesLoader.remove();
  serviceUpdatesLoader.remove();

  return true;
};
