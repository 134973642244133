import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
  init,
  browserTracingIntegration,
  replayIntegration,
} from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

init({
  enabled: environment.production,
  dsn: environment.sentryDsn,
  release: environment.version,
  environment: environment.production ? 'prod' : 'dev',
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
