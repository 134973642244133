import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { addIcons } from 'ionicons';
import { home, barChart, personCircle } from 'ionicons/icons';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
})
export class TabsPage {
  public readonly route = inject(ActivatedRoute);
  constructor() {
    addIcons({ home, barChart, personCircle });
  }
}
