import { APP_INITIALIZER, ErrorHandler, inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import {
  IonicRouteStrategy,
  provideIonicAngular,
  IonApp,
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonBadge,
  IonAvatar,
} from '@ionic/angular/standalone';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { Capacitor } from '@capacitor/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  provideAuth,
  getAuth,
  initializeAuth,
  indexedDBLocalPersistence,
} from '@angular/fire/auth';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs';
import { createErrorHandler, TraceService, setUser } from '@sentry/angular';

import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { environment } from '../environments/environment';
import { DataFirebaseService } from '@app/data-firebase.service';
import { PurchasesService } from '@app/purchases.service';
import { TabsPage } from '@app/tabs/tabs.page';
import { AnalyticsService } from '@app/analytics.service';

@NgModule({
  declarations: [AppComponent, TabsPage],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IonApp,
    IonRouterOutlet,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonBadge,
    IonAvatar,
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() =>
      Capacitor.isNativePlatform()
        ? initializeAuth(getApp(), { persistence: indexedDBLocalPersistence })
        : getAuth(),
    ),
    provideDatabase(() => getDatabase()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    PurchasesService,
    provideIonicAngular(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  private purchasesService = inject(PurchasesService);
  private dataService = inject(DataFirebaseService);
  private analyticsService = inject(AnalyticsService);

  constructor() {
    console.log('[AppModule.constructor]');

    // This is here for early initialization of the purchases service
    console.log(this.purchasesService);

    // This is here for early initialization of the analytics service
    console.log(this.analyticsService);

    this.dataService.user$.pipe(first()).subscribe(async (user) => {
      setUser({
        id: user.uid,
        email: user.email ?? undefined,
      });
      this.analyticsService.identify({
        id: user.uid,
        email: user.email ?? undefined,
        displayName: user.displayName ?? undefined,
      });
    });
  }
}
